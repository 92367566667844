import React, { FC, ReactNode } from 'react'

import { findById } from '../../common/find-by-id'
import { Time } from '../../common/time'
import { DbAsset } from '../../common/types/expense'
import { LogEntry } from '../../common/types/log'
import { DbPayment } from '../../common/types/payment'
import { Column } from '../../common/types/table'
import { UserData } from '../../common/types/user'
import { getDisplayName } from '../../common/user-utils'
import { formatAmount } from '../format-amount'
import { t } from '../i18n'
import { getPaymentDescription } from '../payment-utils'
import { getById } from '../state/user-actions'
import { Button } from './button'
import { LoadingIcon } from './loading-icon'
import { BaseRow, renderTable } from './table'

export interface LogProps {
    visible: boolean
    setVisible: (visible: boolean) => void
    entries: LogEntry[]
    payments: DbPayment[] // Must also include removed payments if any entries point to them
    incoming: boolean
    userData: UserData
    assets?: DbAsset[]
}

const renderLogTable = (props: LogProps): ReactNode => {
    const { visible, entries, payments, incoming, userData, assets } = props

    if (!visible) {
        return null
    }

    if (!userData.usersLimited) {
        return <LoadingIcon color="black" />
    }

    const columns: Column<LogEntry>[] = [
        {
            header: { content: t.time.get() },
            render: (row) => Time.fromIso(row.time).asLocal().shortDateTime(),
        },
        {
            header: { content: t.user.get() },
            render: (row) => {
                const user = getById(userData, row.userId)
                // TODO report warning if user not found?
                return user ? getDisplayName(user) : t.user.get() + ' ' + row.userId
            },
        },
        {
            header: { content: t.info.get() },
            render: (row) => {
                const { action } = row

                if (action === 'payment') {
                    const payment = findById(payments, row.paymentId!)!
                    // TODO show date only if it doesn't match entry time?
                    return getPaymentDescription(payment, incoming) + ' (' + payment.date + ')'
                } else if (action === 'paymentRemove') {
                    const format = t.payments.log.remove[incoming ? 'incoming' : 'outgoing']
                    const payment = findById(payments, row.paymentId!)!
                    return format.get(formatAmount(payment.amount), payment.date)
                } else if (
                    action === 'paymentNet' ||
                    action === 'paymentNetRemove' ||
                    action === 'paymentTax' ||
                    action === 'paymentTaxRemove'
                ) {
                    throw new Error('Net and tax payments not yet supported in log view')
                } else if (
                    action === 'valueChangeCreate' ||
                    action === 'valueChangeRemove' ||
                    action === 'valueChangeConfirm'
                ) {
                    const asset = findById(assets!, row.assetId!)!
                    return t.log.actions[action].get(asset.description)
                } else if (action === 'addFile' || action === 'removeFile') {
                    return t.log.actions[action].get(row.filename!)
                } else {
                    return t.log.actions[action].get()
                }
            },
        },
    ]

    return renderTable<LogEntry & BaseRow>({ columns, rows: entries, domId: 'log' })
}

export const Log: FC<LogProps> = (props) => (
    <div>
        <Button
            onClick={() => props.setVisible(!props.visible)}
            text={t.log.get()}
            className="button--secondary"
        />
        {renderLogTable(props)}
    </div>
)
