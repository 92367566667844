import * as Sentry from '@sentry/react'
import { EventEmitter } from 'fbemitter'
import React from 'react'
import { render, unmountComponentAtNode } from 'react-dom'

import { ServerError } from '../common/server-error'
import { Time } from '../common/time'
import { ErrorCode } from '../common/types/enums'
import { Warning, WrappedError, WrappedWarning } from '../common/types/errors'
import { ErrorPanel } from './components/error-panel'
import { WarningPanel } from './components/warning-panel'
import { onCloseWarnings } from './event-bus'
import { generateId } from './id-utils'

const emitter = new EventEmitter() // TODO use event-bus?

const errors: WrappedError[] = []
let warnings: Warning[] = []
let reportingError = false
let renderErrorPanel = () => {}
let renderWarningPanel = () => {}

const closeWarning = (id: string) => {
    warnings = warnings.filter((warning) => warning.id !== id)
    renderWarningPanel()
}

export const init = () => {
    const errorContainer = document.querySelector('#errors')!
    const warningContainer = document.querySelector('#warnings')!

    const closeErrorPanel = () => unmountComponentAtNode(errorContainer)

    renderErrorPanel = () => {
        const props = { errors, closePanel: closeErrorPanel }
        const element = React.createElement(ErrorPanel, props)
        render(element, errorContainer)
    }

    renderWarningPanel = () => {
        if (warnings.length) {
            const props = { warnings, closeWarning }
            const element = React.createElement(WarningPanel, props)
            render(element, warningContainer)
        } else {
            unmountComponentAtNode(warningContainer)
        }
    }

    onCloseWarnings((errorCode: ErrorCode) => {
        warnings = warnings.filter((warning) => warning.errorCode !== errorCode)
        renderWarningPanel()
    })
}

const fallbackRender = (error1: Error, error2: Error) => {
    try {
        // This method is called in situations where React has likely broken down.
        // Therefore we must render using basic DOM methods instead.

        const container = document.body || document.documentElement
        container.innerHTML = ''

        const div = document.createElement('div')
        container.appendChild(div)

        div.textContent =
            // TODO list of things to try (close and reopen, reload, different browser etc)
            // TODO i18n
            'Vabandame, tekkis tõsisem süsteemiviga.\n\n' +
            'Palun võtke ühendust kasutajatoega või proovige mõnda teist brauserit.\n\n' +
            'Täpsem info:\n\n' +
            error1.stack +
            '\n\n' +
            error2.stack

        div.id = 'severe-error'
    } catch (e) {
        console.error('Error when using fallback render:', e)
    }
}

// Should be warningFromErrorCode, but this works better with processWarning(fromErrorCode(...))
export const fromErrorCode = (errorCode: ErrorCode, additional?: unknown) => {
    const warning: Warning = {
        id: generateId(),
        errorCode,
        shouldReport: true,
        shouldDisplay: true,
        dontReport: () => {
            warning.shouldReport = false
            return warning
        },
        dontDisplay: () => {
            warning.shouldDisplay = false
            return warning
        },
        withMessage: (message) => {
            warning.customMessage = message
            return warning
        },
    }

    if (additional) {
        warning.additional = additional
    }

    return warning
}

export const processWarning = (warning: Warning, stack: string = '') => {
    const { errorCode, customMessage, additional } = warning

    if (warning.shouldDisplay) {
        warnings.push(warning)
        emitter.emit('warning', errorCode)

        // In case we are inside a render() method, we shouldn't start another.
        // Therefore, render the warnings asynchronously.
        setTimeout(renderWarningPanel, 0)
    }

    if (warning.shouldReport) {
        const error = new Error(customMessage || errorCode)
        error.stack = stack

        Sentry.captureException(error, {
            level: 'warning',
            extra: { errorCode, customMessage, additional },
        })
    }
}

const shouldIgnoreError = (error: Error) => {
    // This error is thrown by React Dev Tools.
    // esbuild can only perform dead code elimination when the minifier is enabled,
    // which makes debugging more difficult. The file size difference is small enough
    // to ignore this error.
    return error.message.includes(
        'React is running in production mode, but dead code elimination has not been applied',
    )
}

const shouldTreatAsWarning = (error: Error) => {
    if (error instanceof WrappedWarning) {
        return true
    } else if (error instanceof ServerError) {
        const { errorCode } = error.response
        return errorCode !== 'database-connection-lost' && errorCode !== 'non-200'
    } else {
        return error.message === 'Failed to fetch'
    }
}

const shouldReportError = (error: Error) => {
    if (reportingError) {
        // Avoid infinite loop if reporting an error results in a new error
        return false
    }

    if (error instanceof ServerError) {
        // Most server errors have already been caught on the server side
        // and we don't need to process them twice.
        // However, if the response did not have a 200 status code,
        // the server probably hasn't processed the error yet.
        return error.response.errorCode === 'non-200'
    }

    return true
}

const getErrorCode = (error: Error): ErrorCode => {
    if (error instanceof ServerError) {
        return error.response.errorCode
    } else if (error.message === 'Failed to fetch') {
        return 'failed-to-fetch'
    } else {
        console.log('Can not get error code from error:', error)
        return 'unknown'
    }
}

const getWarningToProcess = (error: Error): Warning => {
    if (error instanceof WrappedWarning) {
        return error.warning
    } else {
        const errorCode = getErrorCode(error)
        return fromErrorCode(errorCode)
    }
}

export const addError = async (error: Error) => {
    if (shouldIgnoreError(error)) {
        return
    }

    if (shouldTreatAsWarning(error)) {
        const warningToProcess = getWarningToProcess(error)

        if (error instanceof ServerError) {
            warningToProcess.dontReport()
            console.warn('Server error:', error.response)
        } else {
            // Do not try to report the error if there are connectivity problems
            if (warningToProcess.errorCode === 'failed-to-fetch') {
                warningToProcess.dontReport()
            }

            console.warn('Warning:', warningToProcess)
        }

        processWarning(warningToProcess, error.stack)
    } else {
        try {
            console.error(error)
            const id = generateId()

            const wrappedError: WrappedError = {
                id,
                time: Time.now(),
                error,
            }

            errors.push(wrappedError)

            // Don't render immediately as this would cause a warning in React.
            window.setTimeout(renderErrorPanel, 0)

            if (shouldReportError(error)) {
                reportingError = true

                Sentry.captureException(error)

                // Must not use try-finally for restoring this variable.
                // This could re-introduce the endless loop we're trying to avoid,
                // as the value would be false by the time we start reporting the new error.
                reportingError = false
            }
        } catch (handlingError) {
            console.error('Error while handling error:', handlingError)
            fallbackRender(error, handlingError as Error)
        }
    }
}

export const onWarning = (callback: (errorCode: ErrorCode) => void) => {
    return emitter.addListener('warning', callback)
}

export const clearListeners = () => emitter.removeAllListeners()
