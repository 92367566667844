import React, { FC, Fragment } from 'react'

import { MIN_REGISTRATION_DATE } from '../../../common/constants'
import { canHaveLongFirstYear, getFirstFiscalYear } from '../../../common/fiscal-year-utils'
import { asMonthNumber, monthNumbers } from '../../../common/month-numbers'
import { range } from '../../../common/range'
import { Day } from '../../../common/time'
import { ApiCompany } from '../../../common/types/company'
import { ValidationError } from '../../../common/types/errors'
import { ChoiceOption, InputValues } from '../../../common/types/inputs'
import { assertViewName } from '../../assert-view-name'
import { t } from '../../i18n'
import { wrapAsStringInput } from '../../input-utils'
import { inputs } from '../../inputs'
import {
    addBankAccount,
    createCompany,
    getCompany,
    getFiscalYearBegin,
    SAVE_GENERAL_PROCESS,
    updateGeneral,
} from '../../state/company-actions'
import { RootData } from '../../state/root-data'
import { valErr } from '../../val-err'
import { BanksList } from '../banks-list'
import { Button } from '../button'
import { renderChoice } from '../choice'
import { DateInput } from '../date-input'
import { Input } from '../input'
import { LoadingPage } from '../loading-page'
import { InitCompanyNav } from './nav'

// TODO i18n

const genInputs = inputs.initCompany.general

const renderRegistrationDate = (
    isNew: boolean,
    inputValues: InputValues,
    valErrors: ValidationError[] | undefined,
) => {
    const input = genInputs.registrationDate

    if (isNew) {
        return React.createElement(
            Fragment,
            null,
            React.createElement(DateInput, {
                input,
                inputValues,
                className: 'date-button date-button--white-bg',
                minDate: Day.fromYmd(MIN_REGISTRATION_DATE),
                maxDate: Day.today(),
            }),
            valErr(valErrors, 'company.registrationDate'),
        )
    } else {
        return Day.fromYmd(input.get(inputValues)).longDate()
    }
}

const renderFiscalYearBegin = (isNew: boolean, inputValues: InputValues) => {
    const fiscInputs = genInputs.fiscalYearBegin

    if (isNew) {
        return React.createElement(
            'div',
            { className: 'init-company__flex-row' },
            renderChoice({
                input: wrapAsStringInput(fiscInputs.dayOfMonth, String, Number),
                inputValues,
                type: 'dropdown',
                options: range(1, 31).map((day) => ({ id: String(day), label: String(day) })),
                groupClassName: 'init-company__dropdown init-company__dropdown--left',
                forceSelection: true,
            }),
            renderChoice({
                input: wrapAsStringInput(fiscInputs.month, String, (value) =>
                    asMonthNumber(Number(value)),
                ),
                inputValues,
                type: 'dropdown',
                options: monthNumbers.map((month) => ({
                    id: String(month),
                    label: t.month[month].get(),
                })),
                groupClassName: 'init-company__dropdown init-company__dropdown--right',
                forceSelection: true,
            }),
        )
    } else {
        const dayOfMonth = fiscInputs.dayOfMonth.get(inputValues)
        const month = fiscInputs.month.get(inputValues)
        return dayOfMonth + '. ' + t.month[month].get().toLowerCase()
    }
}

const renderReadOnlyFirstYear = (firstEnd: Day) =>
    React.createElement(
        'div',
        { className: 'init-company__text-row' },
        React.createElement('div', null, 'Esimese majandusaasta lõpp', ':'),
        React.createElement('div', null, firstEnd.longDate()),
    )

const renderFirstYear = (isNew: boolean, inputValues: InputValues) => {
    const registrationDate = genInputs.registrationDate.get(inputValues)

    if (!registrationDate) {
        return null
    }

    const fiscalYearBegin = getFiscalYearBegin(inputValues)
    const canBeLong = canHaveLongFirstYear(registrationDate, fiscalYearBegin)

    if (!canBeLong) {
        const shortYear = getFirstFiscalYear(registrationDate, fiscalYearBegin, false)
        return renderReadOnlyFirstYear(shortYear.end)
    }

    const input = genInputs.longFirstYear
    const isLong = input.get(inputValues) === 'yes'

    if (!isNew) {
        const firstYear = getFirstFiscalYear(registrationDate, fiscalYearBegin, isLong)
        return renderReadOnlyFirstYear(firstYear.end)
    }

    const shortYear = getFirstFiscalYear(registrationDate, fiscalYearBegin, false)
    const longYear = getFirstFiscalYear(registrationDate, fiscalYearBegin, true)

    const options: ChoiceOption<'yes' | 'no'>[] = [
        { id: 'no', label: shortYear.end.dmy() },
        { id: 'yes', label: longYear.end.dmy() },
    ]

    return React.createElement(
        Fragment,
        null,
        React.createElement(
            'div',
            { className: 'init-company__text-row' },
            'Millal on ettevõtte esimese majandusaasta lõpp?',
        ),
        React.createElement(
            'div',
            { className: 'init-company__input-row' },
            renderChoice({
                type: 'buttons',
                input,
                inputValues,
                options,
                buttonClassName: 'button--numeric button--transparent',
                selectedButtonClassName: 'button--numeric button--transparent-selected',
            }),
        ),
    )
}

const renderAddBankAccountButton = (count: number) => {
    if (count < 3) {
        return React.createElement(
            'div',
            { className: 'init-company__input-row' },
            React.createElement(Button, {
                onClick: addBankAccount,
                text: 'Lisa pangakonto',
                className: 'button--white',
                loadingColor: 'white',
            }),
        )
    } else {
        return null
    }
}

export const InitCompanyGeneral: FC<RootData> = (rootData) => {
    const { companyData, inputValues, processes, session, validationErrors, view } = rootData

    const { isNew } = assertViewName(view, 'InitCompanyGeneral')

    let company: ApiCompany | null = null

    if (!isNew) {
        if (!companyData.companies) {
            return React.createElement(LoadingPage)
        }

        company = getCompany(companyData, session)
    }

    const { bankAccountCount } = companyData

    let requiredChoicesMade = genInputs.vatPayer.get(inputValues) === 'no'

    if (requiredChoicesMade && isNew) {
        // Additional requirements when adding a new company

        const registrationDate = genInputs.registrationDate.get(inputValues)
        requiredChoicesMade = registrationDate !== ''

        if (requiredChoicesMade) {
            const fiscalYearBegin = getFiscalYearBegin(inputValues)
            const canBeLong = canHaveLongFirstYear(registrationDate, fiscalYearBegin)

            if (canBeLong) {
                requiredChoicesMade = genInputs.longFirstYear.get(inputValues) !== ''
            }
        }
    }

    const valErrors = validationErrors[SAVE_GENERAL_PROCESS]
    const vatPayer = genInputs.vatPayer.get(inputValues) === 'yes'

    return React.createElement(
        'div',
        { className: 'init-company' },
        React.createElement(InitCompanyNav, { activeItem: 'general', company }),
        React.createElement(
            'div',
            { className: 'init-company__main' },
            React.createElement(
                'div',
                { className: 'init-company__general-hint' },
                t.initCompany.generalHint.text.get(),
                ' ',
                React.createElement(
                    'a',
                    {
                        href: 'https://ariregister.rik.ee/',
                        target: '_blank',
                        className: 'init-company__link',
                    },
                    t.initCompany.generalHint.link.get(),
                ),
            ),
            React.createElement(
                'div',
                { className: 'init-company__main-inner' },
                isNew &&
                    React.createElement(
                        Fragment,
                        null,
                        React.createElement(
                            'div',
                            { className: 'init-company__text-row' },
                            'Kas ettevõte on käibemaksukohustuslane?',
                        ),
                        React.createElement(
                            'div',
                            { className: 'init-company__input-row' },
                            renderChoice({
                                input: genInputs.vatPayer,
                                inputValues,
                                type: 'buttons',
                                options: [
                                    { id: 'yes', label: 'JAH' },
                                    { id: 'no', label: 'EI' },
                                ],
                                buttonClassName: 'button--transparent button--min-width',
                                selectedButtonClassName:
                                    'button--transparent-selected button--min-width',
                            }),
                        ),
                    ),
                vatPayer &&
                    React.createElement(
                        'div',
                        { className: 'init-company__vat-info' },
                        React.createElement(
                            'div',
                            { className: 'init-company__text-row' },
                            'Kahjuks ei paku Booky programm teenust ettevõtetele, kes on käibemaksukohustuslased.',
                        ),
                        React.createElement(
                            'div',
                            { className: 'init-company__text-row' },
                            'Võtke meiega julgelt ühendust, saame aidata sobiva programmi leidmisel.',
                        ),
                        React.createElement(
                            'a',
                            {
                                href: 'mailto:info@minubooky.ee',
                                className: 'init-company__link',
                            },
                            'info@minubooky.ee',
                        ),
                    ),
                !vatPayer &&
                    React.createElement(
                        Fragment,
                        null,
                        React.createElement(
                            'div',
                            { className: 'init-company__input-row' },
                            React.createElement(Input, {
                                input: genInputs.name,
                                inputValues,
                                placeholder: 'Ettevõtte nimi',
                                className: 'init-company__input init-company__input--wide',
                            }),
                            valErr(valErrors, 'company.name'),
                        ),
                        React.createElement(
                            'div',
                            { className: 'init-company__input-row' },
                            React.createElement(Input, {
                                input: genInputs.regCode,
                                inputValues,
                                placeholder: 'Registrinumber',
                                className: 'init-company__input init-company__input--wide',
                            }),
                            valErr(valErrors, 'company.regCode'),
                        ),
                        React.createElement(
                            'div',
                            { className: 'init-company__input-row' },
                            React.createElement(Input, {
                                input: genInputs.address.street,
                                inputValues,
                                placeholder: 'Aadress',
                                className: 'init-company__input init-company__input--wide',
                            }),
                            valErr(valErrors, 'company.address.street'),
                        ),
                        React.createElement(
                            'div',
                            { className: 'init-company__input-row' },
                            React.createElement(Input, {
                                input: genInputs.address.city,
                                inputValues,
                                placeholder: 'Linn',
                                className: 'init-company__input init-company__input--wide',
                            }),
                            valErr(valErrors, 'company.address.city'),
                        ),
                        React.createElement(
                            'div',
                            { className: 'init-company__input-row' },
                            React.createElement(Input, {
                                input: genInputs.address.postcode,
                                inputValues,
                                placeholder: 'Postiindeks',
                                className: 'init-company__input init-company__input--wide',
                            }),
                            valErr(valErrors, 'company.address.postcode'),
                        ),
                        React.createElement(
                            'div',
                            { className: 'init-company__input-row' },
                            React.createElement(Input, {
                                input: genInputs.email,
                                inputValues,
                                placeholder: 'E-post',
                                className: 'init-company__input init-company__input--wide',
                            }),
                            valErr(valErrors, 'company.email'),
                        ),
                        React.createElement(
                            'div',
                            { className: 'init-company__input-row' },
                            React.createElement(Input, {
                                input: genInputs.website,
                                inputValues,
                                placeholder: 'Koduleht',
                                className: 'init-company__input init-company__input--wide',
                            }),
                            valErr(valErrors, 'company.website'),
                        ),
                        React.createElement(
                            'div',
                            { className: 'init-company__text-row' },
                            'Registrisse kandmise kuupäev',
                        ),
                        React.createElement(
                            'div',
                            { className: 'init-company__input-row' },
                            renderRegistrationDate(isNew, inputValues, valErrors),
                        ),
                        React.createElement(
                            'div',
                            { className: 'init-company__text-row' },
                            'Majandusaasta algus',
                        ),
                        React.createElement(
                            'div',
                            { className: 'init-company__input-row' },
                            renderFiscalYearBegin(isNew, inputValues),
                            valErr(valErrors, 'company.fiscalYearBegin.dayOfMonth', {
                                'over-max': 'Ebakorrektne kuupäev',
                            }),
                            valErr(valErrors, 'company.fiscalYearBegin.month'),
                        ),
                        renderFirstYear(isNew, inputValues),
                        React.createElement(
                            'div',
                            { className: 'init-company__text-row' },
                            'Pangakontod',
                        ),
                        React.createElement(BanksList),
                        ...range(0, bankAccountCount - 1).map((index) => {
                            const accountInputs = genInputs.bankAccounts(index)
                            const isLast = index === bankAccountCount - 1

                            return React.createElement(
                                'div',
                                { className: 'init-company__input-row' },
                                React.createElement(
                                    'div',
                                    { className: 'init-company__flex-row' },
                                    React.createElement(Input, {
                                        input: accountInputs.name,
                                        inputValues,
                                        placeholder: 'Pank',
                                        list: 'bank-names',
                                        className:
                                            'init-company__input init-company__input--left init-company__input--small',
                                    }),
                                    React.createElement(Input, {
                                        input: accountInputs.number,
                                        inputValues,
                                        placeholder: 'Kontonumber',
                                        className:
                                            'init-company__input init-company__input--right init-company__input--small',
                                    }),
                                ),
                                valErr(valErrors, 'company.bankAccounts.' + index + '.name', {
                                    required: 'Palun sisestage panga nimi',
                                }),
                                valErr(valErrors, 'company.bankAccounts.' + index + '.number', {
                                    required: 'Palun sisestage kontonumber',
                                }),
                                // Render under last row to avoid alignment issues
                                isLast
                                    ? valErr(valErrors, 'company.bankAccounts', {
                                          empty: 'Vähemalt üks pangakonto tuleb sisestada',
                                      })
                                    : null,
                            )
                        }),
                        renderAddBankAccountButton(bankAccountCount),
                    ),
                React.createElement(
                    'div',
                    null,
                    React.createElement(Button, {
                        text: 'Edasi',
                        onClick: isNew ? createCompany : updateGeneral,
                        processes,
                        processName: SAVE_GENERAL_PROCESS,
                        disabled: !requiredChoicesMade,
                        className: 'button--white init-company__button init-company__button--wide',
                        loadingColor: 'white',
                    }),
                ),
            ),
        ),
    )
}
