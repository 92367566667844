import React, { FC, ReactNode } from 'react'

import { canChangeStock } from '../../common/access'
import { getExpenseAccountNumber } from '../../common/accounts'
import { MAX_ROWS } from '../../common/constants'
import { expenseAccountTypes, expenseItemTypes, expenseTypes } from '../../common/enums'
import { getNewTotal } from '../../common/expense-utils'
import { findByDbId } from '../../common/find-by-db-id'
import { findById } from '../../common/find-by-id'
import { calculateItem, getItemQuantity, getItemUnitPrice } from '../../common/item-utils'
import { keys } from '../../common/keys'
import { sort, SortOption } from '../../common/sort'
import { Day } from '../../common/time'
import { AccountData } from '../../common/types/account'
import { ApiExpense, PendingStockChange } from '../../common/types/expense'
import { InputValues } from '../../common/types/inputs'
import { Processes } from '../../common/types/processes'
import { Column } from '../../common/types/table'
import { getAccountName } from '../account-utils'
import { getExcelButtonProps } from '../excel-utils'
import { ExcelSpec } from '../excel/types'
import { formatAmount } from '../format-amount'
import { t } from '../i18n'
import { inputs } from '../inputs'
import { toComma } from '../number-utils'
import { renderAmount, renderAmountOrDash } from '../render-amount'
import { renderExcelMoney } from '../render-excel-money'
import { getShowAllButtonProps, wrapExcelButton } from '../standard-page-utils'
import { getCompany } from '../state/company-actions'
import {
    CONFIRM_STOCK_CHANGE_PROCESS,
    confirmStockChange,
    REMOVE_STOCK_CHANGE_PROCESS,
    removeStockChange,
} from '../state/expense-actions'
import { RootData } from '../state/root-data'
import { browserOnly } from '../table-utils'
import { Button } from './button'
import { DateInput } from './date-input'
import { DeleteIcon } from './delete-icon'
import { ExcelButtonProps } from './excel-button'
import { Link } from './link'
import { LoadingIcon } from './loading-icon'
import { LoadingPage } from './loading-page'
import { NoData } from './no-data'
import { renderSortOptions } from './sort-options'
import { BaseRow, renderTable } from './table'
import { ViewIcon } from './view-icon'

interface Row extends BaseRow {
    expenseId: string
    itemId: string
    date: Day
    vendor: string
    account: string
    description: string
    quantity: number
    unit: string
    unitPrice: number
    withoutVat: number
    createTime: string
}

interface Totals {
    withoutVat: number
}

interface ChangeRow extends BaseRow {
    _id: string
    date: string
    totalDiff: number
}

export type SortId = 'date' | 'account' | 'amount' | 'vendor'

const tableInputs = inputs.expense.goods.stock

const LEVEL3_NUMBER = getExpenseAccountNumber(expenseItemTypes.goodsStock)

const alignRight = () => ({ className: 'text-right' })
const getAmountClass = () => ({ className: 'amount' })

const renderDateButton = (inputValues: InputValues) => (
    <DateInput
        input={tableInputs.date}
        inputValues={inputValues}
        text={t.chooseDate.get()}
        maxDate={Day.today()}
        className="button--wide button--primary"
    />
)

const getColumns = (): Column<Row, Totals>[] => [
    {
        header: { content: t.date.get() },
        render: (row) => ({ browser: row.date.dmy(), excel: row.date }),
        excelWidth: 15,
    },
    {
        header: { content: t.expenses.vendor.get() },
        render: (row) => row.vendor,
        excelWidth: 30,
    },
    {
        header: { content: t.account.get() },
        render: (row) => row.account,
        excelWidth: 25,
    },
    {
        header: { content: t.description.get() },
        render: (row) => row.description,
        excelWidth: 30,
    },
    {
        header: { content: t.quantity.get(), getProps: alignRight },
        getProps: getAmountClass,
        render: (row) => ({
            browser: toComma(String(row.quantity)) + ' ' + row.unit,
            excel: row.quantity,
        }),
        excelWidth: 10,
    },
    {
        header: { content: t.unit.get() },
        hideInBrowser: true,
        render: (row) => row.unit,
        excelWidth: 10,
    },
    {
        header: { content: t.unitPrice.get(), getProps: alignRight },
        getProps: getAmountClass,
        render: (row) => ({
            browser: renderAmountOrDash(row.unitPrice) + (row.unit ? '/' + row.unit : ''),
            excel: renderExcelMoney(row.unitPrice, false),
        }),
        excelWidth: 15,
    },
    {
        header: { content: t.total.get(), getProps: alignRight },
        getProps: getAmountClass,
        getTotalProps: getAmountClass,
        render: (row) => ({
            browser: renderAmountOrDash(row.withoutVat),
            excel: renderExcelMoney(row.withoutVat, false),
        }),
        getTotal: (totals) => renderAmount(totals.withoutVat),
        getExcelTotal: (totals) => ({ type: 'sum-money', value: totals.withoutVat }),
        excelWidth: 15,
    },
    {
        header: {
            content: t.actions.get(),
            getProps: () => ({ className: 'text-center' }),
        },
        getProps: () => ({ className: 'actions text-center' }),
        render: browserOnly((row) => <ViewIcon href={'#/expenses/view/' + row.expenseId} />),
        hideInExcel: true,
    },
]

const getRows = (
    date: string,
    expenses: ApiExpense[],
    totals: Totals,
    sortOption: SortOption<Row>,
    accountData: AccountData,
): Row[] => {
    const rows: Row[] = []

    for (const expense of expenses) {
        if (expense.type !== expenseTypes.regular || !expense.confirmed || expense.date > date) {
            continue
        }

        const { items, calculationMode, vendor } = expense
        const expenseDate = Day.fromYmd(expense.date)
        const createTime = expense.log[0].time

        for (const item of items!) {
            const { id: itemId, type, description } = item

            if (type === expenseItemTypes.goodsStock) {
                const account = getAccountName(
                    LEVEL3_NUMBER,
                    item.account,
                    accountData,
                    expenseAccountTypes.goods,
                )

                const unit = item.unit ? t.enums.units[item.unit].get() : ''
                const quantity = getItemQuantity(item, date)
                const unitPrice = getItemUnitPrice(calculationMode, item, date)
                const withoutVat = calculateItem(calculationMode, item, date).payableWithoutVat
                totals.withoutVat += withoutVat

                rows.push({
                    expenseId: expense._id,
                    itemId,
                    date: expenseDate,
                    vendor: vendor.name,
                    account,
                    description,
                    quantity,
                    unit,
                    unitPrice,
                    withoutVat,
                    createTime,
                })
            }
        }
    }

    return sort(rows, sortOption)
}

const renderChangeButton = (visible: boolean) => {
    if (visible) {
        return (
            <a className="button button--primary" href="#/expenses/add-stock-change">
                {t.expenses.stock.change.get()}
            </a>
        )
    } else {
        return null
    }
}

const renderPendingChanges = (
    hasStockChangeAccess: boolean,
    changes: PendingStockChange[] | null,
    expenses: ApiExpense[],
    processes: Processes,
): ReactNode => {
    if (!hasStockChangeAccess || (changes && !changes.length)) {
        return null
    }

    if (
        !changes ||
        processes.has(CONFIRM_STOCK_CHANGE_PROCESS) ||
        processes.has(REMOVE_STOCK_CHANGE_PROCESS)
    ) {
        return (
            <div className="top-margin">
                <LoadingIcon color="black" />
            </div>
        )
    }

    const columns: Column<ChangeRow>[] = [
        {
            header: { content: t.date.get() },
            render: (row) => Day.fromYmd(row.date).dmy(),
        },
        {
            header: { content: t.expenses.stock.changeTotal.get() },
            render: (row) => formatAmount(row.totalDiff),
        },
        {
            header: {
                content: t.actions.get(),
                getProps: () => ({ className: 'text-center' }),
            },
            getProps: () => ({ className: 'actions' }),
            render: browserOnly((row) => (
                <span>
                    <ViewIcon href={'#/expenses/edit-stock-change/' + row._id} />
                    <Link
                        text={t.confirm.get()}
                        // TODO custom confirmation UI
                        onClick={() =>
                            confirm(t.confirm.confirmChange.get())
                                ? confirmStockChange(row._id)
                                : null
                        }
                        className="confirm-link"
                    />
                </span>
            )),
        },
        {
            render: browserOnly((row) => (
                <DeleteIcon
                    // TODO custom confirmation UI
                    onClick={() =>
                        confirm(t.confirm.removeChange.get()) ? removeStockChange(row._id) : null
                    }
                />
            )),
        },
    ]

    return (
        <>
            <h3 className="section-title">{t.expenses.stock.pendingChanges.get()}</h3>
            {renderTable({
                columns,
                rows: changes.map((change): ChangeRow => {
                    const { _id, date, items } = change
                    let totalDiff = 0

                    for (const changeItem of items) {
                        const { expenseId, itemId } = changeItem
                        const expense = findByDbId(expenses, expenseId)!
                        const { calculationMode } = expense
                        const expenseItem = findById(expense.items!, itemId)!

                        const oldWithoutVat = calculateItem(
                            calculationMode,
                            expenseItem,
                            date,
                        ).payableWithoutVat
                        const newWithoutVat = getNewTotal(
                            calculationMode,
                            changeItem,
                            expenseItem,
                            date,
                        )

                        totalDiff += newWithoutVat - oldWithoutVat
                    }

                    return { _id, date, totalDiff }
                }),
                tableClassName: 'main-table stock-list__unconfirmed',
            })}
        </>
    )
}

export const StockList: FC<RootData> = (rootData) => {
    const {
        accountData,
        companyData,
        expenseData: { expenses, pendingStockChanges },
        inputValues,
        processes,
        progress,
        session,
    } = rootData

    if (!expenses || !accountData.accounts || !companyData.companies) {
        return <LoadingPage />
    }

    const anyStock = expenses.some(
        (expense) =>
            expense.type === expenseTypes.regular &&
            expense.items!.some((item) => item.type === expenseItemTypes.goodsStock),
    )

    if (!anyStock) {
        return (
            <NoData
                addRoute="#/expenses/add/regular"
                addButtonText={t.expenses.add.goods.stock.get()}
            />
        )
    }

    const company = getCompany(companyData, session)

    const sortOptions: { [S in SortId]: SortOption<Row> } = {
        date: [
            { getKey: (row) => row.date.toTimestamp(), reverse: true },
            { getKey: (row) => row.createTime, reverse: true },
        ],
        account: [{ getKey: (row) => row.account }],
        amount: [{ getKey: (row) => row.withoutVat, reverse: true }],
        vendor: [{ getKey: (row) => row.vendor }],
    }

    // TODO undup
    const showAll = tableInputs.showAll.get(inputValues)
    const date = tableInputs.date.get(inputValues)
    const sortId = tableInputs.sort.get(inputValues)

    const totals: Totals = { withoutVat: 0 }
    const allRows = getRows(date, expenses, totals, sortOptions[sortId], accountData)
    const hasMore = allRows.length > MAX_ROWS
    const rows: Row[] = showAll ? allRows : allRows.slice(0, MAX_ROWS)
    const columns = getColumns()

    const title = t.expenses.stock.get()
    const subtitle = t.asOf.get(Day.fromYmd(date).longDate())

    let excelButton: ExcelButtonProps | undefined

    if (rows.length) {
        const excelSpec: ExcelSpec<Row, Totals> = {
            columns,
            rows,
            totals,
            outputName: title + ' ' + subtitle,
        }

        excelButton = getExcelButtonProps(
            excelSpec,
            t.expenses.processed.stock.get(),
            processes,
            progress,
            'button button--wide button--primary',
        )
    }

    const hasStockChangeAccess = canChangeStock(session!.companyRole, company.status)

    return (
        <div className="content-area stock-list">
            <div className="content">
                <h1 className="title">
                    {title}
                    <span className="title__sub-title">{subtitle}</span>
                </h1>
                <div className="flex space-between flex-end">
                    {renderSortOptions({
                        input: tableInputs.sort,
                        inputValues,
                        options: keys(sortOptions).map((key) => ({
                            id: key,
                            label: t.expenses.sortOption[key].get(),
                        })),
                    })}
                    <div className="standard-buttons flex-vertical flex-end">
                        {renderDateButton(inputValues)}
                        {excelButton && wrapExcelButton(excelButton)}
                    </div>
                </div>
                {renderTable({
                    columns,
                    rows,
                    totals,
                    stickyHeader: true,
                    tableClassName: 'main-table',
                    wrapperClassName: 'main-table-wrapper',
                })}
                <div className="top-margin">
                    {!showAll && hasMore && (
                        <Button {...getShowAllButtonProps(tableInputs.showAll)} />
                    )}{' '}
                    {renderChangeButton(hasStockChangeAccess)}
                </div>
                {renderPendingChanges(
                    hasStockChangeAccess,
                    pendingStockChanges,
                    expenses,
                    processes,
                )}
            </div>
        </div>
    )
}
