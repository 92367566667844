import { BALANCE_LEAVES } from '../../common/accounts'
import { assertNever } from '../../common/assert-never'
import { MAX_BANK_ACCOUNTS } from '../../common/constants'
import { InitDateType, initDateTypes } from '../../common/enums'
import { findByDbId } from '../../common/find-by-db-id'
import { canHaveLongFirstYear, getFiscalYearForCompany } from '../../common/fiscal-year-utils'
import { Day } from '../../common/time'
import { CardPaymentType } from '../../common/types/card-payment'
import {
    ApiCompany,
    BillingDetails,
    CompanyData,
    CompanyInput,
    CompanyUpdate,
} from '../../common/types/company'
import { InputValues } from '../../common/types/inputs'
import { SimpleData } from '../../common/types/reports'
import { ApiSession } from '../../common/types/session'
import * as api from '../api'
import { emitAfterStoreReset, emitUpdatedSession } from '../event-bus'
import { formatAmountForInput } from '../format-amount-for-input'
import { getNumeric } from '../get-numeric'
import { inputs } from '../inputs'
import { setRoute } from '../route-utils'
import { clearInputs } from './input-actions'
import { setInvalid } from './invalid-cache-actions'
import { loadCardPayments, loadCompanies, loadInterimBalance, loadServerConf } from './load-actions'
import { run } from './process-actions'
import { dispatch, getState, reset } from './store'
import { clearErrors, processError } from './validation-actions'

export const SAVE_GENERAL_PROCESS = 'company/save/general'
export const SAVE_DATE_PROCESS = 'company/save/general'
export const SAVE_INTERIM_BALANCE_PROCESS = 'company/save/interim-balance'
export const INIT_PAYMENT_PROCESS = 'company/init-payment'
export const ACTIVATE_PROCESS = 'company/activate'
export const ARCHIVE_PROCESS = 'company/archive'

const genInputs = inputs.initCompany.general
const bilInputs = inputs.initCompany.billing

export const getCompany = (companyData: CompanyData, session: ApiSession | null) => {
    if (!companyData.companies) {
        throw new Error('Companies not loaded')
    }

    if (!session) {
        throw new Error('Must be logged in to get company')
    }

    const { companyId } = session

    if (!companyId) {
        throw new Error('Company not selected')
    }

    const company = findByDbId(companyData.companies, companyId)

    if (!company) {
        throw new Error('Company not found: ' + companyId)
    }

    return company
}

export const loadCompany = async () => {
    await loadCompanies()
    const { companyData, session } = getState()
    return getCompany(companyData, session)
}

const getBankAccountsInput = (inputValues: InputValues) => {
    const bankAccounts = []
    let lastNonEmpty = -1

    for (let i = 0; i < 3; i += 1) {
        const accountInputs = genInputs.bankAccounts(i)

        const bankAccount = {
            name: accountInputs.name.get(inputValues).trim(),
            number: accountInputs.number.get(inputValues).trim(),
        }

        bankAccounts.push(bankAccount)
        const isEmpty = bankAccount.name === '' && bankAccount.number === ''

        if (!isEmpty) {
            lastNonEmpty = i
        }
    }

    // Omit empty bank accounts at the end of the array
    return bankAccounts.slice(0, lastNonEmpty + 1)
}

const getCompanyUpdate = (inputValues: InputValues): CompanyUpdate => {
    return {
        name: genInputs.name.get(inputValues).trim(),
        regCode: genInputs.regCode.get(inputValues).trim(),
        address: {
            street: genInputs.address.street.get(inputValues).trim(),
            city: genInputs.address.city.get(inputValues).trim(),
            postcode: genInputs.address.postcode.get(inputValues).trim(),
        },
        vatId: '',
        email: genInputs.email.get(inputValues).trim(),
        website: genInputs.website.get(inputValues).trim(),
        bankAccounts: getBankAccountsInput(inputValues),
    }
}

export const getFiscalYearBegin = (inputValues: InputValues) => ({
    dayOfMonth: genInputs.fiscalYearBegin.dayOfMonth.get(inputValues),
    month: genInputs.fiscalYearBegin.month.get(inputValues),
})

const getCompanyInput = (inputValues: InputValues): CompanyInput => {
    const registrationDate = genInputs.registrationDate.get(inputValues)
    const fiscalYearBegin = getFiscalYearBegin(inputValues)

    const canBeLong = canHaveLongFirstYear(registrationDate, fiscalYearBegin)
    const longFirstYear = canBeLong ? genInputs.longFirstYear.get(inputValues) === 'yes' : false

    return {
        ...getCompanyUpdate(inputValues),
        registrationDate,
        fiscalYearBegin,
        longFirstYear,
    }
}

const getBillingDetails = (inputValues: InputValues): BillingDetails => ({
    billingName: bilInputs.contact.name.get(inputValues).trim(),
    billingEmail: bilInputs.contact.email.get(inputValues).trim(),
})

export const initAddForm = () => {
    clearErrors(SAVE_GENERAL_PROCESS)
    dispatch(({ companyData }) => (companyData.bankAccountCount = 1))
    clearInputs(genInputs)
    genInputs.longFirstYear.set('no')
}

export const addBankAccount = () =>
    dispatch(({ companyData }) => {
        if (companyData.bankAccountCount < MAX_BANK_ACCOUNTS) {
            companyData.bankAccountCount += 1
        }
    })

export const createCompany = async () =>
    run(SAVE_GENERAL_PROCESS, async () => {
        const { inputValues } = getState()
        const company = getCompanyInput(inputValues)
        const newSession = await api.createCompany(company)

        reset(false)
        emitUpdatedSession(newSession)
        emitAfterStoreReset()

        setInvalid('company')
        await loadCompanies()
        setRoute('#/init-company/date')
    })

export const initGeneral = async () => {
    clearErrors(SAVE_GENERAL_PROCESS)
    dispatch(({ companyData }) => (companyData.bankAccountCount = 1))
    clearInputs(genInputs)

    const company = await loadCompany()
    inputs.initCompany.rev.set(company.rev)

    genInputs.name.set(company.name)
    genInputs.regCode.set(company.regCode)
    genInputs.address.street.set(company.address.street)
    genInputs.address.city.set(company.address.city)
    genInputs.address.postcode.set(company.address.postcode)
    genInputs.email.set(company.email)
    genInputs.website.set(company.website)
    genInputs.registrationDate.set(company.registrationDate)
    genInputs.fiscalYearBegin.dayOfMonth.set(company.fiscalYearBegin.dayOfMonth)
    genInputs.fiscalYearBegin.month.set(company.fiscalYearBegin.month)

    for (const [index, bankAccount] of company.bankAccounts.entries()) {
        const accountInputs = genInputs.bankAccounts(index)
        accountInputs.name.set(bankAccount.name)
        accountInputs.number.set(bankAccount.number)
    }

    genInputs.vatPayer.set('no')
}

export const updateGeneral = async () =>
    run(SAVE_GENERAL_PROCESS, async () => {
        const { inputValues } = getState()
        const rev = inputs.initCompany.rev.get(inputValues)
        const company = getCompanyUpdate(inputValues)
        await api.updateCompanyGeneral(rev, company)
        setInvalid('company')
        setRoute('#/init-company/date')
    })

export const getInitDate = (type: InitDateType, company: ApiCompany): Day => {
    if (type === InitDateType.today) {
        return Day.today()
    } else if (type === InitDateType.startOfMonth) {
        return Day.today().firstOfMonth()
    } else if (type === InitDateType.startOfFiscalYear) {
        const fiscalYear = getFiscalYearForCompany(Day.today(), company)

        if (fiscalYear.isFirst) {
            // In the first fiscal year, the start date is equal to the registration date.
            // The init date must be at least one day after the registration date.
            return fiscalYear.start.addDays(1)
        } else {
            return fiscalYear.start
        }
    } else {
        throw assertNever(type, 'init date type')
    }
}

const detectInitDateType = (company: ApiCompany) => {
    for (const type of initDateTypes) {
        const dateForType = getInitDate(type, company)

        if (dateForType.ymd() === company.interimDate) {
            return type
        }
    }

    // No match, could happen if the date was last updated in the previous month, etc.
    // In such a case, use today as the default.
    return InitDateType.today
}

export const initDate = async () => {
    const company = await loadCompany()
    inputs.initCompany.rev.set(company.rev)
    const dateType = detectInitDateType(company)
    inputs.initCompany.dateType.set(dateType)
}

export const updateInitDate = async () =>
    run(SAVE_DATE_PROCESS, async () => {
        const company = await loadCompany()
        const { inputValues } = getState()
        const rev = inputs.initCompany.rev.get(inputValues)
        const dateType = inputs.initCompany.dateType.get(inputValues)

        const date = getInitDate(dateType, company)
        await api.updateInitDate(rev, date.ymd())

        setInvalid('company')
        setRoute('#/init-company/billing')
    })

export const initBilling = async () => {
    clearErrors(INIT_PAYMENT_PROCESS)
    clearInputs(inputs.initCompany.billing)
    const [company] = await Promise.all([loadCompany(), loadServerConf(), loadCardPayments()])
    inputs.initCompany.rev.set(company.rev)

    const { billingName, billingEmail } = company
    bilInputs.contact.name.set(billingName)
    bilInputs.contact.email.set(billingEmail)
}

const updateBillingDetails = async () => {
    const { inputValues } = getState()
    const rev = inputs.initCompany.rev.get(inputValues)
    const details = getBillingDetails(inputValues)
    await api.updateBillingDetails(rev, details)
    setInvalid('company')
}

const initCardTransaction = async (type: CardPaymentType) => {
    const { paymentLink } = await api.initCardTransaction(type)
    document.location.replace(paymentLink)
}

export const initFirstPayment = async () => {
    // We don't use "run(INIT_PAYMENT_PROCESS, ..." here because it would dispatch endProcess
    // immediately after calling form.submit().
    // As the page will stay open for a bit after submit() is called, this would result in
    // the loading icon reverting to a button that the user can click again.
    // Instead, we want to keep showing the loading icon until the browser navigates away.
    // We only want to dispatch endProcess if an error occurs.

    const processName = INIT_PAYMENT_PROCESS
    dispatch((draft) => (draft.processes = draft.processes.add(processName)))
    clearErrors(processName)

    let paymentSucceeded = false

    try {
        await Promise.all([updateBillingDetails(), loadServerConf()])
        await initCardTransaction(CardPaymentType.first)
        paymentSucceeded = true
    } catch (error) {
        processError(error as Error, processName)
    } finally {
        if (!paymentSucceeded) {
            // @ts-expect-error Looks like a TypeScript bug
            dispatch((draft) => (draft.processes = draft.processes.delete(processName)))
        }
    }
}

export const initCardUpdate = async () => {
    // We don't use "run(INIT_PAYMENT_PROCESS, ..." here because it would dispatch endProcess
    // immediately after calling form.submit().
    // As the page will stay open for a bit after submit() is called, this would result in
    // the loading icon reverting to a button that the user can click again.
    // Instead, we want to keep showing the loading icon until the browser navigates away.
    // We only want to dispatch endProcess if an error occurs.

    const processName = INIT_PAYMENT_PROCESS
    dispatch((draft) => (draft.processes = draft.processes.add(processName)))

    try {
        await loadServerConf()
        await initCardTransaction(CardPaymentType.update)
    } catch (error) {
        // @ts-expect-error Looks like a TypeScript bug
        dispatch((draft) => (draft.processes = draft.processes.delete(processName)))
        throw error
    }
}

export const initPaymentStatus = async () => Promise.all([loadCompanies(), loadCardPayments()])

export const activateCompany = async () =>
    run(ACTIVATE_PROCESS, async () => {
        const newSession = await api.activateCompany()

        reset(false)
        emitUpdatedSession(newSession)
        emitAfterStoreReset()

        setRoute('#/dashboard')
    })

export const initInterimBalance = async () => {
    const [company] = await Promise.all([loadCompany(), loadInterimBalance()])
    inputs.initCompany.rev.set(company.rev)
    const { companyData } = getState()
    const interimBalance = companyData.interimBalance!

    for (const level4Number of BALANCE_LEAVES) {
        const accInput = inputs.financialHistory.balance(level4Number)

        accInput.set(
            level4Number in interimBalance
                ? formatAmountForInput(interimBalance[level4Number])
                : '',
        )
    }
}

const getInterimBalanceInput = (inputValues: InputValues): SimpleData<string> => {
    const data: SimpleData<string> = {}

    for (const level4Number of BALANCE_LEAVES) {
        const input = inputs.financialHistory.balance(level4Number)
        const amount = getNumeric(input, inputValues)

        if (amount !== '') {
            data[level4Number] = amount
        }
    }

    return data
}

export const updateInterimBalance = async () =>
    run(SAVE_INTERIM_BALANCE_PROCESS, async () => {
        const { inputValues } = getState()
        const rev = inputs.initCompany.rev.get(inputValues)
        const interimBalance = getInterimBalanceInput(inputValues)
        await api.updateInterimBalance(rev, interimBalance)

        setInvalid('interim-balance')
        setInvalid('company')
        setRoute('#/financial-history/balance/view')
    })

export const archiveCompany = async () =>
    run(ARCHIVE_PROCESS, async () => {
        const newSession = await api.archiveCompany()

        reset(false)
        emitUpdatedSession(newSession)
        emitAfterStoreReset()

        // TODO special page with "company account has been closed" message?
        setRoute('#/select-company')
    })
