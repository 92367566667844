import classnames from 'classnames'
import React, { FC, ReactNode } from 'react'

import {
    PENSION_PAYMENT_L3,
    SOCIAL_TAX_L3,
    UNEMPLOYMENT_EMPLOYEE_L3,
    UNEMPLOYMENT_EMPLOYER_L3,
} from '../../common/accounts'
import { amountEquals } from '../../common/amount-equals'
import { roundToNearestCent } from '../../common/amount-utils'
import {
    calculateLabourCost,
    getLabourCostMinMonth,
    getLabourTaxRates,
} from '../../common/labour-cost-utils'
import { range } from '../../common/range'
import { Day } from '../../common/time'
import { CalculationMode } from '../../common/types/enums'
import { ValidationError } from '../../common/types/errors'
import { InputValues } from '../../common/types/inputs'
import { ApiLabourCost, LabourCostCalc, LabourCostViewMode } from '../../common/types/labour-cost'
import { Processes } from '../../common/types/processes'
import { Column } from '../../common/types/table'
import { upperCaseFirst } from '../../common/upper-case-first'
import { amountFromString } from '../amount-from-string'
import { formatAmount } from '../format-amount'
import { formatAmountForInput } from '../format-amount-for-input'
import { t } from '../i18n'
import { createCustomInput } from '../input-utils'
import { inputs } from '../inputs'
import { renderAmount } from '../render-amount'
import { setRoute } from '../route-utils'
import {
    calculateLabourCostFromInputs,
    create,
    getMonth,
    nextStep,
    previousStep,
    SAVE_PROCESS,
} from '../state/labour-cost-actions'
import { browserOnly } from '../table-utils'
import { valErr } from '../val-err'
import { Button } from './button'
import { Checkbox } from './checkbox'
import { renderChoice } from './choice'
import { Input } from './input'
import { MenuToggle } from './menu-toggle'
import { MonthInput } from './month-input'
import { BaseRow, renderTable } from './table'

interface Props {
    mode: Exclude<LabourCostViewMode, LabourCostViewMode.list>
    listRoute: string
    existing?: ApiLabourCost
    interimDate?: string // Required if mode is addInit
    inputValues: InputValues
    processes: Processes
    valErrors: ValidationError[] | undefined
}

interface CalcRow extends BaseRow {
    name: ReactNode
    amount: number
    separator?: true
}

const getTitle = (addMode: boolean) => (addMode ? t.labourCosts.add.get() : t.labourCost.get())

const renderMonth = (props: Props) => {
    const { mode, existing, inputValues, valErrors } = props

    const valErrElement = valErr(valErrors, 'labourCost.month', {
        'under-min': t.labourCosts.month.underMin.get(),
        'over-max': t.labourCosts.month.overMax.get(),
    })

    if (mode === LabourCostViewMode.addInit) {
        const interimDate = Day.fromYmd(props.interimDate!)

        const earliest = getLabourCostMinMonth(interimDate)
        const latest = Day.today().addYears(3)

        const yearOptions = range(earliest.year(), latest.year()).map((year) => ({
            id: String(year),
            label: String(year),
        }))

        return (
            <>
                <div>{t.month.get()}</div>
                <MonthInput
                    input={inputs.labourCost.month}
                    inputValues={inputValues}
                    buttonClassName="button--transparent"
                    selectedButtonClassName="button--transparent-selected"
                />
                <div>
                    {renderChoice({
                        type: 'dropdown',
                        inputValues,
                        input: inputs.labourCost.year,
                        options: yearOptions,
                        forceSelection: true,
                        groupClassName: 'input input--white year-dropdown',
                    })}
                </div>
                {valErrElement}
            </>
        )
    } else {
        const month = mode === LabourCostViewMode.view ? existing!.month : getMonth(inputValues)

        return (
            <>
                <div className="labour-cost__month">
                    {upperCaseFirst(Day.fromYm(month).longMonth())}
                </div>
                {valErrElement}
            </>
        )
    }
}

const renderNote = (
    addMode: boolean,
    inputValues: InputValues,
    valErrors: ValidationError[] | undefined,
) => (
    <>
        <div className="top-margin medium">
            <div>{t.explanation.get()}</div>
        </div>
        <div>
            <Input
                input={inputs.labourCost.note}
                inputValues={inputValues}
                className={classnames('input input--white sidebar__input', {
                    'input--disabled': !addMode,
                })}
                disabled={!addMode}
            />
            {valErr(valErrors, 'labourCost.note')}
        </div>
    </>
)

const renderSourceAmount = (
    mode: LabourCostViewMode,
    inputValues: InputValues,
    valErrors: ValidationError[] | undefined,
) => {
    if (mode === LabourCostViewMode.addInit) {
        return (
            <>
                <div className="top-margin medium">
                    <div>{t.labourCosts.amount.get()}</div>
                    {renderChoice({
                        type: 'buttons',
                        input: inputs.labourCost.amountMode,
                        inputValues,
                        options: [
                            { id: 'gross', label: t.labourCosts.gross.get() },
                            { id: 'net', label: t.labourCosts.net.get() },
                        ],
                        forceSelection: true,
                        buttonClassName: 'button--transparent',
                        selectedButtonClassName: 'button--transparent-selected',
                    })}
                </div>
                <div>
                    <Input
                        input={inputs.labourCost.amount}
                        inputValues={inputValues}
                        className="input input--white sidebar__input amount"
                    />
                    {' €'}
                    {valErr(valErrors, 'labourCost.gross')}
                </div>
            </>
        )
    } else {
        return null
    }
}

const renderPensionChoice = (mode: LabourCostViewMode, inputValues: InputValues) => {
    if (mode === LabourCostViewMode.addInit) {
        return (
            <div className="top-margin medium">
                <Checkbox
                    input={inputs.labourCost.calculatePension}
                    inputValues={inputValues}
                    className="checkbox checkbox--aligned"
                />{' '}
                {t.labourCosts.usePensionPayment.get()}
            </div>
        )
    } else {
        return null
    }
}

const renderCalc = (mode: LabourCostViewMode, calc: LabourCostCalc, inputValues: InputValues) => {
    if (mode === LabourCostViewMode.addInit) {
        return null
    }

    if (mode === LabourCostViewMode.addSocial) {
        return (
            <div className="top-margin medium">
                {t.labourCosts.gross.get()}
                {': '}
                {renderAmount(calc.gross)}
            </div>
        )
    }

    const columns: Column<CalcRow>[] = [
        {
            getProps: (row) => {
                if (row.separator) {
                    return { colSpan: 2 }
                } else {
                    return { className: 'labour-calc-table__cell labour-calc-table__cell--text' }
                }
            },
            render: browserOnly((row) => {
                if (row.separator) {
                    return <hr className="labour-cost__separator" />
                } else {
                    return row.name
                }
            }),
        },
        {
            getProps: () => ({
                className: 'labour-calc-table__cell labour-calc-table__cell--amount',
            }),
            render: (row) => renderAmount(row.amount),
        },
    ]

    const separatorRow: CalcRow = { separator: true, name: '', amount: 0 }
    const month = getMonth(inputValues)

    const {
        socialTaxRate,
        unemploymentEmployerRate,
        unemploymentEmployeeRate,
        defaultPensionRate,
        incomeTaxRate,
    } = getLabourTaxRates(month)

    const rows: CalcRow[] = [
        {
            name: t.payroll.get() + ':',
            amount: calc.payroll,
        },
        {
            name:
                t.account[SOCIAL_TAX_L3].get() +
                ' (' +
                roundToNearestCent(socialTaxRate * 100) +
                '%):',
            amount: calc.socialTax,
        },
    ]

    if (calc.socialTaxIncrease) {
        rows.push({
            name: t.labourCosts.socialTaxIncrease.get() + ':',
            amount: calc.socialTaxIncrease,
        })
    }

    rows.push(
        {
            name:
                t.account[UNEMPLOYMENT_EMPLOYER_L3].get() +
                ' (' +
                roundToNearestCent(unemploymentEmployerRate * 100) +
                '%):',
            amount: calc.unemploymentEmployer,
        },
        separatorRow,
        {
            name: t.labourCosts.gross.get() + ':',
            amount: calc.gross,
        },
        separatorRow,
        {
            name: (
                <span>
                    {t.account[PENSION_PAYMENT_L3].get()}
                    {' (' + roundToNearestCent(defaultPensionRate * 100) + '%):'}
                </span>
            ),
            amount: calc.pensionPayment,
        },
        {
            name:
                t.account[UNEMPLOYMENT_EMPLOYEE_L3].get() +
                ' (' +
                roundToNearestCent(unemploymentEmployeeRate * 100) +
                '%):',
            amount: calc.unemploymentEmployee,
        },
        {
            name: t.incomeTax.get() + ' (' + roundToNearestCent(incomeTaxRate * 100) + '%):',
            amount: calc.incomeTax,
        },
        separatorRow,
        {
            name: t.labourCosts.net.get() + ':',
            amount: calc.net,
        },
    )

    return renderTable({
        columns,
        rows,
        noHeader: true,
        noWrapper: true,
        tableClassName: 'labour-calc-table',
    })
}

const getTaxFreeInput = (taxFreeMode: 'automatic' | 'manual', calc: LabourCostCalc) => {
    if (taxFreeMode === 'automatic') {
        return createCustomInput({
            inputType: 'string',
            get: () => formatAmountForInput(calc.expectedTaxFree),
            set: () => {
                throw new Error()
            },
        })
    } else {
        return inputs.labourCost.customTaxFree
    }
}

const renderTaxFreeModeInput = (inputValues: InputValues, calc: LabourCostCalc) => (
    <div>
        {renderChoice({
            type: 'buttons',
            input: inputs.labourCost.taxFreeMode,
            inputValues,
            options: [
                { id: 'automatic', label: t.labourCosts.taxFree.modes.automatic.get() },
                { id: 'manual', label: t.labourCosts.taxFree.modes.manual.get() },
            ],
            forceSelection: true,
            buttonClassName: 'button--transparent',
            selectedButtonClassName: 'button--transparent-selected',
            afterChange: (newValue) => {
                if (newValue === 'manual') {
                    inputs.labourCost.customTaxFree.set(formatAmountForInput(calc.expectedTaxFree))
                }
            },
        })}
    </div>
)

const renderTaxFreeWarning = (
    taxFreeMode: 'automatic' | 'manual',
    invalidCustomTaxFree: boolean,
    calc: LabourCostCalc,
) => {
    if (taxFreeMode === 'manual') {
        if (invalidCustomTaxFree && !isNaN(calc.net)) {
            // The isNaN check should eliminate all cases where the custom amount is
            // under the minimum, so we show the over-max warning.
            return <div className="sidebar__warning">{t.validation['over-max'].get()}</div>
        }

        if (calc.actualTaxFree > calc.expectedTaxFree) {
            return <div className="sidebar__warning">{t.labourCosts.taxFree.warning.get()}</div>
        }
    }

    return null
}

const renderTaxFree = (
    mode: LabourCostViewMode,
    inputValues: InputValues,
    calc: LabourCostCalc,
    taxFreeMode: CalculationMode,
    valErrors: ValidationError[] | undefined,
    invalidCustomTaxFree: boolean,
) => {
    if (mode === LabourCostViewMode.view || mode === LabourCostViewMode.addFinal) {
        return (
            <div className="top-margin medium">
                {t.labourCosts.taxFree.get()}
                {': '}
                {renderAmount(calc.actualTaxFree)}
            </div>
        )
    } else if (mode === LabourCostViewMode.addInit) {
        return (
            <>
                <div className="top-margin medium">
                    <div>{t.labourCosts.taxFree.get()}</div>
                </div>
                {renderTaxFreeModeInput(inputValues, calc)}
                <div>
                    <Input
                        input={getTaxFreeInput(taxFreeMode, calc)}
                        inputValues={inputValues}
                        className={classnames('input input--white sidebar__input amount', {
                            'input--disabled': taxFreeMode === 'automatic',
                        })}
                        disabled={taxFreeMode === 'automatic'}
                    />
                    {' €'}
                    {valErr(valErrors, 'labourCost.taxFree')}
                    {renderTaxFreeWarning(taxFreeMode, invalidCustomTaxFree, calc)}
                </div>
            </>
        )
    } else {
        return null
    }
}

const renderSocialTaxIncrease = (
    mode: LabourCostViewMode,
    calc: LabourCostCalc,
    inputValues: InputValues,
) => {
    if (mode !== LabourCostViewMode.addSocial) {
        return null
    }

    const month = getMonth(inputValues)
    const { minSocialTax } = getLabourTaxRates(month)

    return (
        <>
            <div className="top-margin text-multiline">
                {t.labourCosts.socialTaxIncrease.info.get(
                    formatAmount(calc.socialTax),
                    formatAmount(minSocialTax),
                )}
            </div>
            <div className="top-margin">
                <Checkbox input={inputs.labourCost.increaseSocialTax} inputValues={inputValues} />{' '}
                {t.labourCosts.socialTaxIncrease.choice.get()}
            </div>
        </>
    )
}

const renderLink = (text: string, href: string) => (
    <div>
        <a href={href} target="_blank" className="sidebar__link" rel="noreferrer">
            {text}
        </a>
    </div>
)

const renderLinks = () => (
    <div className="top-margin medium">
        {t.labourCosts.links.header.get()}
        <div className="labour-cost__links">
            {renderLink(
                t.labourCosts.links.taxFree.get(),
                'https://www.emta.ee/et/eraklient/tulu-deklareerimine/maksuvaba-tulu-alates-1-jaanuarist-2018',
            )}
            {renderLink(
                t.labourCosts.links.taxRates.get(),
                'https://www.emta.ee/et/eraklient/tulu-deklareerimine/maksumaarad',
            )}
            {renderLink(
                t.labourCosts.links.pension.get(),
                'https://www.pensionikeskus.ee/ii-sammas/kogumispension-ehk-ii-sammas/',
            )}
            {renderLink(
                t.labourCosts.links.varyingSalary.get(),
                'https://www.emta.ee/et/eraklient/tulu-deklareerimine/oluline-maksuvaba-tulu-arvestamisel-alates-1-jaanuarist-2018',
            )}
        </div>
    </div>
)

const renderButtons = (mode: LabourCostViewMode, processes: Processes, saveDisabled: boolean) => (
    <div className="labour-cost__buttons">
        {(mode === LabourCostViewMode.addSocial || mode === LabourCostViewMode.addFinal) && (
            <Button
                text={t.back.get()}
                onClick={previousStep}
                className="button--transparent sidebar__wide-button bottom-margin"
            />
        )}
        {(mode === LabourCostViewMode.addInit || mode === LabourCostViewMode.addSocial) && (
            <Button
                text={t.forward.get()}
                onClick={nextStep}
                className="button--white sidebar__wide-button"
            />
        )}
        {mode === LabourCostViewMode.addFinal && (
            <Button
                text={t.save.get()}
                onClick={create}
                processes={processes}
                processName={SAVE_PROCESS}
                className="button--white sidebar__wide-button"
                loadingColor="white"
                disabled={saveDisabled}
            />
        )}
    </div>
)

export const LabourCostEdit: FC<Props> = (props) => {
    const { mode, listRoute, existing, inputValues, processes, valErrors } = props

    const viewMode = mode === LabourCostViewMode.view
    const addMode = !viewMode

    if (viewMode && !existing) {
        throw new Error('Existing labour cost is required in view mode')
    }

    const calc = existing
        ? calculateLabourCost(existing)
        : calculateLabourCostFromInputs(inputValues)

    const taxFreeMode = inputs.labourCost.taxFreeMode.get(inputValues)
    let invalidCustomTaxFree = false

    if (taxFreeMode === 'manual') {
        const customAmount = amountFromString(inputs.labourCost.customTaxFree.get(inputValues))
        invalidCustomTaxFree = !amountEquals(customAmount, calc.actualTaxFree)
    }

    const saveDisabled = invalidCustomTaxFree || isNaN(calc.net)

    return (
        <div className="sidebar sidebar--flex labour-cost open">
            <div className="labour-cost__main">
                <MenuToggle onClick={() => setRoute(listRoute)} />
                <h1 className="title sidebar__title">{getTitle(addMode)}</h1>
                {renderMonth(props)}
                {renderNote(addMode, inputValues, valErrors)}
                {renderSourceAmount(mode, inputValues, valErrors)}
                {renderCalc(mode, calc, inputValues)}
                {renderPensionChoice(mode, inputValues)}
                {renderTaxFree(
                    mode,
                    inputValues,
                    calc,
                    taxFreeMode,
                    valErrors,
                    invalidCustomTaxFree,
                )}
                {renderSocialTaxIncrease(mode, calc, inputValues)}
                {renderLinks()}
            </div>
            {renderButtons(mode, processes, saveDisabled)}
        </div>
    )
}
