import React, { FC } from 'react'

import { assetTypes, calculationModes } from '../../common/enums'
import { findById } from '../../common/find-by-id'
import { calculateAssetCurrent } from '../../common/item-utils'
import { range } from '../../common/range'
import { Day } from '../../common/time'
import { AssetType, CalculationMode } from '../../common/types/enums'
import { ValidationError } from '../../common/types/errors'
import { ApiExpense } from '../../common/types/expense'
import { ChoiceOption, Input, InputValues } from '../../common/types/inputs'
import { Column } from '../../common/types/table'
import { upperCaseFirst } from '../../common/upper-case-first'
import { formatAmountOrDash } from '../format-amount'
import { t } from '../i18n'
import { renderChoiceOrValue, renderInputOrValue, wrapAsStringInput } from '../input-utils'
import { inputs } from '../inputs'
import { calculateAssetCurrentFromInputs, calculateAssetInitialFromInputs } from '../item-utils'
import { removeAsset } from '../state/expense-actions'
import { browserOnly } from '../table-utils'
import { valErr } from '../val-err'
import { DateInput } from './date-input'
import { DeleteIcon } from './delete-icon'
import { BaseRow, renderTable } from './table'
import { VatChoice } from './vat-choice'

interface Props {
    editMode: boolean
    assetIds: string[]
    inputValues: InputValues
    valErrors: ValidationError[] | undefined
    vatPayer: boolean
    calculationMode: CalculationMode
    expense?: ApiExpense // Only if !editMode
}

export interface AssetInputs {
    type: Input<AssetType>
    description: Input<string>
    lifetime: Input<number>
    amortBegin: Input<string>
    withoutVat: Input<string>
    other: Input<string>
    vatPercentage: Input<string>
}

interface Row extends BaseRow {
    id: string
    inputs: AssetInputs
    type: AssetType
    amortRate: number
    eol: string
    withVat: number
}

const getLeftAligned = () => ({ className: 'text-left' })
const getCenterAligned = () => ({ className: 'center' })
const getRightAligned = () => ({ className: 'text-right' })
const getRightAlignedNumeric = () => ({ className: 'text-right numeric' })

const getColumns = (props: Props) => {
    const { editMode, inputValues, valErrors, vatPayer, calculationMode } = props

    const lifetimeColumn: Column<Row> = {
        header: { content: t.lifetime.get() },
        getProps: getCenterAligned,
        render: browserOnly((row) => {
            if (row.type === assetTypes.land) {
                return '-'
            }

            const options: ChoiceOption<string>[] = [
                { id: '1', label: '1 ' + t.year.get() },
                ...range(2, 50).map((y) => ({ id: String(y), label: y + ' ' + t.years.get() })),
            ]

            return renderChoiceOrValue(editMode, {
                input: wrapAsStringInput(row.inputs.lifetime, String, Number),
                inputValues,
                type: 'dropdown',
                options,
                forceSelection: true,
            })
        }),
    }

    const eolColumn: Column<Row> = {
        header: { content: t.assets.eol.get() },
        getProps: getCenterAligned,
        render: (row) => row.eol,
    }

    const columns: Column<Row>[] = [
        {
            header: { content: t.type.get(), getProps: getLeftAligned },
            render: (row) => t.enums.assetTypes[row.type].get(),
        },
        {
            header: { content: t.description.get(), getProps: getLeftAligned },
            render: browserOnly((row) => (
                <div>
                    {renderInputOrValue(editMode, { input: row.inputs.description, inputValues })}
                    {valErr(valErrors, 'expense.assets.' + row.id + '.description')}
                </div>
            )),
        },
        editMode ? lifetimeColumn : eolColumn,
        {
            header: { content: t.assets.amortRate.get(), getProps: getRightAligned },
            getProps: getRightAlignedNumeric,
            render: (row) => Math.round(row.amortRate * 100) + '%',
        },
        {
            header: { content: t.assets.amortBegin.get() },
            getProps: getCenterAligned,
            render: browserOnly((row) => {
                if (row.type === assetTypes.land) {
                    return '-'
                }

                const input = row.inputs.amortBegin

                if (editMode) {
                    return (
                        <div>
                            <DateInput input={input} inputValues={inputValues} />
                            {valErr(valErrors, 'expense.assets.' + row.id + '.amortBegin', {
                                'under-min': t.assets.amortBegin.underMin.get(),
                            })}
                        </div>
                    )
                } else {
                    return Day.fromYmd(input.get(inputValues)).dmy()
                }
            }),
        },
        {
            header: {
                content: (vatPayer ? t.sumWithoutVat.short.get() : t.sum.get()) + ' €',
                getProps: editMode ? undefined : getRightAligned,
            },
            getProps: editMode ? getCenterAligned : getRightAlignedNumeric,
            render: browserOnly((row) => (
                <div>
                    {renderInputOrValue(
                        editMode,
                        {
                            input: row.inputs.withoutVat,
                            inputValues,
                            className: 'amount',
                        },
                        (value) => formatAmountOrDash(Number(value)),
                    )}
                    {valErr(valErrors, 'expense.assets.' + row.id + '.totals.withoutVat')}
                </div>
            )),
        },
    ]

    if (calculationMode === calculationModes.automatic) {
        columns.push({
            header: {
                content: t.assets.other.get() + ' €',
                getProps: editMode ? undefined : getRightAligned,
            },
            getProps: editMode ? getCenterAligned : getRightAlignedNumeric,
            render: browserOnly((row) => (
                <div>
                    {renderInputOrValue(
                        editMode,
                        {
                            input: row.inputs.other,
                            inputValues,
                            className: 'amount',
                        },
                        (value) => formatAmountOrDash(Number(value)),
                    )}
                    {valErr(valErrors, 'expense.assets.' + row.id + '.totals.other')}
                </div>
            )),
        })
    }

    if (vatPayer) {
        columns.push({
            header: {
                content: t.vat.short.get(),
                getProps: editMode ? undefined : getRightAligned,
            },
            getProps: editMode ? getCenterAligned : getRightAlignedNumeric,
            render: browserOnly((row) => (
                <div>
                    <VatChoice
                        editMode={editMode}
                        input={row.inputs.vatPercentage}
                        inputValues={inputValues}
                        shouldIncludeNone={true}
                    />
                    {valErr(valErrors, 'expense.assets.' + row.id + '.totals.vatPercentage')}
                </div>
            )),
        })
    }

    columns.push({
        header: { content: t.total.get() + ' €', getProps: getRightAligned },
        getProps: getRightAlignedNumeric,
        render: (row) => formatAmountOrDash(row.withVat),
    })

    if (editMode) {
        columns.push({
            render: browserOnly((row) => <DeleteIcon onClick={() => removeAsset(row.id)} />),
        })
    }

    return columns
}

const getRows = ({ assetIds, editMode, expense, inputValues, vatPayer }: Props) => {
    const refDate = Day.today()

    return assetIds.map((id): Row => {
        const rowInputs = inputs.expense.asset(id)
        const type = rowInputs.type.get(inputValues)
        const { withVat } = calculateAssetInitialFromInputs(rowInputs, inputValues, vatPayer)
        let current

        if (editMode) {
            current = calculateAssetCurrentFromInputs(refDate, rowInputs, inputValues, vatPayer)
        } else {
            const asset = findById(expense!.assets!, id)!
            current = calculateAssetCurrent(refDate, asset)
        }

        const { amortRate, eolDate } = current
        const eol = eolDate ? upperCaseFirst(eolDate.shortMonth()) : '-'
        return { id, inputs: rowInputs, type, amortRate, eol, withVat }
    })
}

export const AssetItemTable: FC<Props> = (props) =>
    renderTable({
        columns: getColumns(props),
        rows: getRows(props),
        domId: 'assets',
        tableClassName: 'table table--bottom-border',
        wrapperClassName: 'asset-table-wrapper',
    })
