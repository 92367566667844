import { Component } from 'react'

import { addError } from '../error-manager'
import { showBlank } from '../show-blank'
import { RootData } from '../state/root-data'
import { renderTopLevelComponent } from '../top-level'

interface State {
    hasError: boolean
}

export class Main extends Component<RootData, State> {
    static getDerivedStateFromError(): Partial<State> {
        return { hasError: true }
    }

    override state = { hasError: false }

    override componentDidCatch(error: Error) {
        if (process.env.NODE_ENV === 'development' && typeof process !== 'undefined') {
            process.BOOKY_TEST_RENDER_ERROR = error
        }

        if (process.env.NODE_ENV === 'production') {
            void addError(error)
        }

        // The error will be displayed on an overlay by error-manager.
        // Show blank page to prevent another render error.
        showBlank(this.props)
    }

    override render() {
        if (this.state.hasError) {
            return null
        }

        return renderTopLevelComponent(this.props)
    }
}
