import { BaseRow } from '../../client/components/table'
import { Time } from '../time'
import { ErrorCode } from './enums'

export interface WrappedError extends BaseRow {
    id: string
    time: Time
    error: Error
}

export interface Warning {
    id: string
    errorCode: ErrorCode
    customMessage?: string
    additional?: unknown
    shouldReport: boolean
    shouldDisplay: boolean
    dontReport: () => Warning
    dontDisplay: () => Warning
    withMessage: (message: string) => Warning
}

export interface ValidationError {
    key: string
    type: string // TODO ValidationErrorType
    [additional: string]: any
}

export type ValidationErrors = Record<string, ValidationError[] | undefined>

type OverrideFunction = (error: ValidationError) => string | null
export type Overrides = Record<string, string | OverrideFunction>

export class WrappedWarning extends Error {
    public readonly warning: Warning

    constructor(warning: Warning) {
        super('Warning: ' + warning.errorCode)
        Object.setPrototypeOf(this, new.target.prototype)
        this.warning = warning
    }
}
