import React, { FC } from 'react'

import { sort } from '../../common/sort'
import { Day } from '../../common/time'
import { AllAccounts } from '../../common/types/account'
import { ApiEntry, EntryItem } from '../../common/types/entry'
import { InputValues } from '../../common/types/inputs'
import { Column } from '../../common/types/table'
import { getLevel4AccountName, PRESETS } from '../entry-utils'
import { t } from '../i18n'
import { inputs } from '../inputs'
import { renderAmount } from '../render-amount'
import { setRoute } from '../route-utils'
import { copyEntryFrom, remove, REMOVE_PROCESS } from '../state/entry-actions'
import { RootData } from '../state/root-data'
import { browserOnly } from '../table-utils'
import { Button } from './button'
import { DeleteIcon } from './delete-icon'
import { ExpandToggle } from './expand-toggle'
import { Link } from './link'
import { LoadingIcon } from './loading-icon'
import { BaseRow, renderTable } from './table'

interface EntryRow extends BaseRow {
    isItemsRow: false
    isExpanded: boolean
    entry: ApiEntry
}

interface ItemsRow extends BaseRow {
    isItemsRow: true
    items: EntryItem<number>[]
}

type Row = EntryRow | ItemsRow

const getLeftAligned = () => ({ className: 'text-left' })
const getCenterAligned = () => ({ className: 'text-center' })

const renderItem = (item: EntryItem<number>, accounts: AllAccounts) => (
    <li key={item.id}>
        {t[item.type].get()}
        {' \u2022 '}
        {renderAmount(item.amount)}
        {' \u2022 '}
        {getLevel4AccountName(item.accountNumber, accounts)}
        {' ('}
        {item.accountNumber})
    </li>
)

const renderItemsRow = (items: EntryItem<number>[], accounts: AllAccounts | null) => {
    if (!accounts) {
        return <LoadingIcon color="black" />
    } else {
        return <ul>{items.map((item) => renderItem(item, accounts))}</ul>
    }
}

const renderDateCell = (row: Row, accounts: AllAccounts | null) => {
    if (row.isItemsRow) {
        return renderItemsRow(row.items, accounts)
    } else {
        return (
            <>
                <ExpandToggle expanded={row.isExpanded} />
                {Day.fromYmd(row.entry.date).dmy()}
            </>
        )
    }
}

const getColumns = (inputValues: InputValues, accounts: AllAccounts | null) => {
    const toggleRow = (row: EntryRow) => {
        const input = inputs.entries.row(row.entry._id).expanded
        const isExpanded = input.get(inputValues)
        input.set(!isExpanded)
    }

    const columns: Column<Row>[] = [
        {
            header: { content: t.entries.date.get(), getProps: getLeftAligned },
            getProps: (row) => {
                if (row.isItemsRow) {
                    return { colSpan: columns.length }
                } else {
                    return { onClick: () => toggleRow(row), className: 'clickable' }
                }
            },
            render: browserOnly((row) => renderDateCell(row, accounts)),
        },
        {
            header: { content: t.entries.description.get(), getProps: getLeftAligned },
            getProps: (row) => {
                if (row.isItemsRow) {
                    return null
                } else {
                    return { onClick: () => toggleRow(row), className: 'clickable' }
                }
            },
            render: (row) => (row.isItemsRow ? '' : row.entry.description),
        },
        {
            header: {
                content: t.actions.get(),
                getProps: () => ({ className: 'text-center' }),
                span: 2,
            },
            getProps: getCenterAligned,
            render: browserOnly((row) => {
                if (row.isItemsRow) {
                    return null
                }

                return (
                    <span>
                        <Link
                            text={t.copy.get()}
                            onClick={async () => copyEntryFrom(row.entry)}
                            className="entries__copy-link"
                        />
                    </span>
                )
            }),
        },
        {
            render: browserOnly((row) => {
                if (row.isItemsRow) {
                    return null
                }

                const onClick = () =>
                    confirm(t.confirm.removeEntry.get()) ? remove(row.entry._id) : null
                return <DeleteIcon onClick={onClick} />
            }),
        },
    ]

    return columns
}

const getRows = (entries: ApiEntry[], inputValues: InputValues) => {
    const rows: Row[] = []

    const sortedEntries = sort(entries, [{ getKey: (entry) => entry.date, reverse: true }], {
        clone: true,
    })

    for (const entry of sortedEntries) {
        const isExpanded = inputs.entries.row(entry._id).expanded.get(inputValues)
        const entryRow: EntryRow = { isItemsRow: false, isExpanded, entry }
        rows.push(entryRow)

        if (isExpanded) {
            rows.push({ isItemsRow: true, items: entry.items })
        }
    }

    return rows
}

const renderEntriesTable = (
    entries: ApiEntry[],
    inputValues: InputValues,
    accounts: AllAccounts | null,
) =>
    renderTable({
        columns: getColumns(inputValues, accounts),
        rows: getRows(entries, inputValues),
        tableClassName: 'table table--bottom-border entries__table',
    })

const renderPresets = () => {
    return (
        <div className="entry-presets">
            <div className="entry-presets__title">{t.entries.presets.title.get()}</div>
            {PRESETS.map((preset) => {
                const href = '#/entries/add/' + preset.id

                return (
                    <div key={preset.id}>
                        <a href={href} className="button button--secondary entry-presets__button">
                            {t.entries.presets[preset.id].get()}
                        </a>
                    </div>
                )
            })}
        </div>
    )
}

export const EntryList: FC<RootData> = (rootData) => {
    const {
        accountData,
        entryData: { entries },
        inputValues,
        processes,
    } = rootData

    if (!entries || processes.has(REMOVE_PROCESS)) {
        return (
            <div className="content-area">
                <div className="content">
                    <LoadingIcon color="black" />
                </div>
            </div>
        )
    }

    return (
        <div className="content-area">
            <div className="content entries">
                <h1 className="title">{t.entries.title.get()}</h1>
                <div className="flex">
                    {renderEntriesTable(entries, inputValues, accountData.allAccounts)}
                    {renderPresets()}
                </div>
                <div className="top-margin">
                    <Button
                        onClick={() => setRoute('#/entries/add')}
                        text={t.addNew.get()}
                        className="button--primary top-margin"
                    />
                </div>
            </div>
        </div>
    )
}
